import { auth, uiConfig, deleteMyAccount, getUser} from './firebase.ts';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


import React, { Component } from 'react';
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';


class MainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      isModalOpen: false,
      showConfirmation: false
    };
  }

  componentDidMount() {
    const unregisterAuthObserver = auth.onAuthStateChanged(async user => {
      this.setState({ isLoggedIn: !!user });

      const uid = user?.uid;
        const chessUser = await getUser(user?.uid);
        this.setState({ currentChessUser: chessUser });
        console.log("Current user: " + chessUser);
        console.log("Current user 1: " + chessUser?.username);
        console.log("Current user 2: " + chessUser?.['username']);
    });

    // Initialize FirebaseUI on mount
      //const ui = getUi();
      // theUi.start('#firebaseui-auth-container', uiConfig);

      return () => {
        unregisterAuthObserver(); 
        // theUi.reset(); // Reset FirebaseUI on unmount
      };
  }

  handleSignOut = () => {
    auth.signOut()
      .then(() => {
        this.setState({ isLoggedIn: false });
      })
      .catch(error => {
        console.error("Error signing out: ", error);
      });
  }


  handleOpenModal = () => {
    console.log('handleOpenModal');
    this.setState({ isModalOpen: true });
  };

  handleCloseModal = () => {
    console.log('handleCloseModal');
    this.setState({ isModalOpen: false });
  };

  handleCloseConfirmationModal = () => {
    console.log('handleCloseConfirmationModal');
    this.setState({ showConfirmation: false });
  };

  handleConfirm = () => {
    // Execute your JavaScript function here
    console.log('User confirmed!');
    
    deleteMyAccount().then(() => {
      this.setState({ isLoggedIn: false, currentChessUser: null, isModalOpen: false, showConfirmation: true });
      console.log("Account deleted!");
      this.setState({ showConfirmation: true });
    })
    
    
  };

  

  render() {
    return (
      <>
      <Modal
                show={this.state.isModalOpen}
                backdrop="static"
                onRequestClose={this.handleCloseModal}
                contentLabel="Confirmation Modal">
                <Modal.Header closeButton>
                  <Modal.Title>Delete Account?</Modal.Title>
                </Modal.Header>
              <Modal.Body>Do you really want to delete your account?
Deleting your account means all your data will be deleted from our servers.
Account deletion is IRREVERSIBLE!
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleCloseModal}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={this.handleConfirm}>Yes</Button>
              </Modal.Footer>
            </Modal>
        <Modal
              show={this.state.showConfirmation}
              backdrop="static"
              contentLabel="Confirmation Modal">
              <Modal.Header closeButton>
                  <Modal.Title>Account deleted!</Modal.Title>
              </Modal.Header>

              <Modal.Body>Your account has been deleted. You can come back to us and create an account again.
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={this.handleCloseConfirmationModal}>OK</Button>
              </Modal.Footer>
            </Modal>
        {this.state.isLoggedIn ? (
          <>
            &nbsp;
            <Button onClick={this.handleSignOut}>Logout</Button>
            {
              this.state.currentChessUser != null ? (
              <>
                &nbsp;<Button variant="danger" onClick={this.handleOpenModal}>DELETE MY ACCOUNT</Button>
              </>) : (<></>) 
            }

            <>
              

            
            </>
          </>
        ) : (
          <><p>
            Log in to delete your account
            </p>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
          </>
        )}
      </>
    );
  }
}

export default MainComponent;