import logo from './logo.png';
import chessboard from './chessboard.png';
import './App.css';
import './firebase.ts';
import MainComponent from './MainComponent.js';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div class="parent">
          <img class="chessboard" src={chessboard}/>
          <img class="logo" src={logo} />
        </div>
        
        <MainComponent/>
      </header>
    </div>
  );
}
export default App;