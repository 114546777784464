/// Firebase
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged, 
  EmailAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider, 
  signInWithPopup  } from "firebase/auth";
import { getFirestore, collection, getDocs, getDoc, doc } from 'firebase/firestore/lite';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { getFunctions, httpsCallable } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDt6NUoSlJ47WdY98lmhNhENb2Fyl3MlNk",
  authDomain: "checkmate-17950.firebaseapp.com",
  databaseURL: "https://checkmate-17950-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "checkmate-17950",
  storageBucket: "checkmate-17950.appspot.com",
  messagingSenderId: "806087671114",
  appId: "1:806087671114:web:0f4c94992dd01ffe9595b3",
  measurementId: "G-5R1YVZLE46"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const firebaseui = require('firebaseui');


onAuthStateChanged(auth, async (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    const uid = user.uid;
    const chessUser = await getUser(user.uid);
    //console.log("Current user: " + chessUser);
    //console.log("Current user 1: " + chessUser?.username);
    //console.log("Current user 2: " + chessUser?.['username']);

    // ...
  } else {
    // User is signed out
    // ...
  }
});

// Initialize the FirebaseUI Widget using Firebase.
// const  ui = new firebaseui.auth.AuthUI(auth);

// Provider configuration
export const uiConfig = {
  signInFlow: 'popup', // or 'redirect'
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID,
    FacebookAuthProvider.PROVIDER_ID, // Add more as needed
    EmailAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false, // Avoid redirects after sign-in  
  },
};


// Get a list of cities from your database
export async function getUser(uid) {
    const userRef = doc(db, "users", uid);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      return userSnap.data();
    } else {
      return null;
    }
    /*const usersCol = collection(db, 'users').user("uid");
    const usersSnapshot = await getDoc(usersCol);
    const userList = usersSnapshot.docs.map(doc => doc.data());
    return userList;*/
}

export function isLoggedIn() {
  return auth.currentUser != null;
}



//const users = await getUsers();
//console.log(users);

export function deleteMyAccount() {
  const functions = getFunctions(app, "europe-west1");
  const callableReturnMessage = httpsCallable(functions, 'deleteMyAccount');

  return callableReturnMessage().then((result) => {
    console.log(result.data.output);
    return auth.signOut();
  }).catch((error) => {
    console.log(`error: ${JSON.stringify(error)}`);
  });

}